import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { InputValid } from "../../validations/InputValid";
import { toast } from "react-toastify";
import { useSetaddressMutation } from "../../../redux/addressApi";

export const UpdateWalletAddress = (props) => {
  const [setaddress] = useSetaddressMutation();
  const [wallet_address, setwallet_address] = useState("");
  const [wallet_addressErr, setwallet_addressErr] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    if (props.currentRecord) {
      setwallet_address(props.currentRecord.wallet_address);

      setId(props.currentRecord.id);
    }
  }, [props]);
  const handleClose = () => {
    setwallet_address("");

    setwallet_addressErr("");

    props.setUpdateShow(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "wallet_address") {
      setwallet_address(value);
      const err = InputValid(name, value);
      setwallet_addressErr(err);
    }
  };

  const onsubmit = async (e) => {
    const checkQuestion = InputValid("wallet_address", wallet_address);
    if (checkQuestion) {
      setwallet_addressErr(checkQuestion);
      return false;
    }

    const data = {
      wallet_address,
      type: "Wallet",
      id,
    };
    setaddress(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };
  return (
    <>
      {" "}
      <Modal show={props.updateShow} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Update Address</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Smart Contract Address </Form.Label>
              <Form.Control
                name="wallet_address"
                onChange={handlechange}
                type="text"
                value={wallet_address}
              ></Form.Control>
              <span style={{ color: "red" }}>{wallet_addressErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
