import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { InputValid } from "../../validations/InputValid";
import { toast } from "react-toastify";
import { useSetGamingMutation } from "../../../redux/GamingApi";
import { ImageValid } from "../../validations/ImageValid";

export const AddGamingModal = (props) => {
    const [setGaming] = useSetGamingMutation();
  const [gaming, setgaming] = useState("");
  const [gamingErr, setgamingErr] = useState("");
  const [imageURL, setImageUrl] = useState("");
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const handleClose = () => {
    setgaming("");
    setgamingErr("");

    props.setShow(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "gaming") {
      setgaming(value);
      const err = InputValid(name, value);
      setgamingErr(err);
    }
  };

  const onsubmit = async (e) => {
    const checkQuestion = InputValid("gaming", gaming);
    if (checkQuestion) {
      setgamingErr(checkQuestion);
      return false;
    }
    let checkImage = ImageValid("gamingImage", image);
    if (checkImage) {
      setImageErr(checkImage);
      return false;
    }

    const formdata = new FormData();
    formdata.append("gaming", gaming);

    formdata.append("gaming_image", image);
    setGaming(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };
  const handlechangeimage = (e) => {
    let { name } = e.target;

    const image = e.target.files[0];
    if (name === "gamingImage") {
      setImage(image);
      setImageUrl(URL.createObjectURL(image));
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };
  return (
    <>
      {" "}
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Add gaming</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Gaming </Form.Label>
              <Form.Control
                name="gaming"
                onChange={handlechange}
                type="text"
                value={gaming}
              ></Form.Control>
              <span style={{ color: "red" }}>{gamingErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Gaming Image</Form.Label>

              <Form.Control
                type="file"
                name="gamingImage"
                onChange={handlechangeimage}
                accept=".png, .jpg, .jpeg"
              />
              <span style={{ color: "red" }}>{imageErr}</span>
            </Form.Group>
            <img
              style={{ width: "100px" }}
              src={imageURL}
              className="img-fluid"
              alt=""
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};