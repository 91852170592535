import React, { useEffect } from "react";
import Login from "./Components/auth/Login";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth } from "./AuthContext";
import Dashboard from "./Components/Widgets/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import Team from "./Components/pages/Team";
import Faq from "./Components/pages/Faq";
import Announcement from "./Components/pages/Announcement";
import WhitePaper from "./Components/pages/WhitePaper";
import Partnership from "./Components/pages/Partnership";
import Video from "./Components/pages/Video";
import SideBar from "./Components/pages/SideBar";
import TokenSaleTime from "./Components/pages/TokenSaleTime";
import { SmartContract } from "./Components/pages/SmartContract";
import { WalletAddress } from "./Components/pages/WalletAddress";
import { AboutUs } from "./Components/pages/AboutUs";
import { Ai } from "./Components/pages/Ai";
import { Deso } from "./Components/pages/Deso";
import { Gaming } from "./Components/pages/Gaming";
import { Vision } from "./Components/pages/Vision";
import { Mission } from "./Components/pages/Mission";
import { SocialMedia } from "./Components/pages/SocialMedia";
import { RoadMap } from "./Components/pages/RoadMap";
import { AddRoadmap } from "./Components/partial/roadmap/AddRoadmap";
import { Document } from "./Components/pages/Document";
import { AddDocument } from "./Components/partial/document/AddDocument";

function App() {
  const { login } = useAuth();

  useEffect(() => {
    // Check if a JWT token is stored in localStorage and log in the user if found
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      login();
    }
  }, [login]);
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute component={<Dashboard />} />}
          />
          <Route
            path="/team"
            element={<ProtectedRoute component={<Team />} />}
          />
          <Route path="/faq" element={<ProtectedRoute component={<Faq />} />} />
          <Route
            path="/announcement"
            element={<ProtectedRoute component={<Announcement />} />}
          />
          <Route
            path="/whitepaper"
            element={<ProtectedRoute component={<WhitePaper />} />}
          />
          <Route
            path="/partnership"
            element={<ProtectedRoute component={<Partnership />} />}
          />
          <Route
            path="/video"
            element={<ProtectedRoute component={<Video />} />}
          />
          <Route
            path="/sidebar"
            element={<ProtectedRoute component={<SideBar />} />}
          />
          <Route
            path="/token-sale-time"
            element={<ProtectedRoute component={<TokenSaleTime />} />}
          />
          <Route
            path="/address"
            element={<ProtectedRoute component={<SmartContract />} />}
          />
          <Route
            path="/wallet-address"
            element={<ProtectedRoute component={<WalletAddress />} />}
          />
          <Route
            path="/about-us"
            element={<ProtectedRoute component={<AboutUs />} />}
          />
          <Route path="/ai" element={<ProtectedRoute component={<Ai />} />} />
          <Route
            path="/deso"
            element={<ProtectedRoute component={<Deso />} />}
          />
          <Route
            path="/gaming"
            element={<ProtectedRoute component={<Gaming />} />}
          />
          <Route
            path="/vision"
            element={<ProtectedRoute component={<Vision />} />}
          />
          <Route
            path="/mission"
            element={<ProtectedRoute component={<Mission />} />}
          />
          <Route
            path="/social-media"
            element={<ProtectedRoute component={<SocialMedia />} />}
          />
          <Route
            path="/roadmap"
            element={<ProtectedRoute component={<RoadMap />} />}
          />
          <Route
            path="/add-roadmap/:id"
            element={<ProtectedRoute component={<AddRoadmap />} />}
          />
          <Route
            path="/add-roadmap"
            element={<ProtectedRoute component={<AddRoadmap />} />}
          />
          <Route
            path="/document"
            element={<ProtectedRoute component={<Document />} />}
          />
       

          {/* <Route
            path="/add-document/:id"
            element={<ProtectedRoute component={<AddDocument />} />}
          />
          <Route
            path="/add-document"
            element={<ProtectedRoute component={<AddDocument />} />}
          /> */}
        </Routes>
      </Router>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
