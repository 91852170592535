import {
    addVisionApi,getVisionApi
  } from "../Components/constant/Api";
  import { myApi } from "./api";
  
  export const videoApi = myApi.injectEndpoints({
    endpoints: (builder) => ({
      getVision: builder.query({
        query: () => ({
          url: getVisionApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? "" : "";
        },
        providesTags: (_) => ["vision"],
      }),
      setVision: builder.mutation({
        query: (post) => ({
          url: addVisionApi,
          method: "POST",
          body: post,
        }),
        invalidatesTags: (_) => ["vision", "dashboard"],
      }),
      
 
     
    }),
  });
  
  export const {useGetVisionQuery,useSetVisionMutation} = videoApi;
  