import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { InputValid } from "../../validations/InputValid";
import { toast } from "react-toastify";
import { useSetaddressMutation } from "../../../redux/addressApi";

export const UpdateAddressModal = (props) => {
  const [setaddress] = useSetaddressMutation();
  const [smart_contract_address, setsmart_contract_address] = useState("");
  const [smart_contract_addressErr, setSmart_contract_addressErr] =
    useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    if (props.currentRecord) {
      setsmart_contract_address(props.currentRecord.smart_contract_address);

      setId(props.currentRecord.id);
    }
  }, [props]);
  const handleClose = () => {
    setsmart_contract_address("");

    setSmart_contract_addressErr("");

    props.setUpdateShow(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "smart_contract_address") {
      setsmart_contract_address(value);
      const err = InputValid(name, value);
      setSmart_contract_addressErr(err);
    }
  };

  const onsubmit = async (e) => {
    const checkQuestion = InputValid(
      "smart_contract_address",
      smart_contract_address
    );
    if (checkQuestion) {
      setSmart_contract_addressErr(checkQuestion);
      return false;
    }

    const data = {
      smart_contract_address,
      type: "Contract",
      id,
    };
    setaddress(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <>
      {" "}
      <Modal show={props.updateShow} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Update Address</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Smart Contract Address </Form.Label>
              <Form.Control
                name="smart_contract_address"
                onChange={handlechange}
                type="text"
                value={smart_contract_address}
              ></Form.Control>
              <span style={{ color: "red" }}>{smart_contract_addressErr}</span>
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label> Answer </Form.Label>
        <Form.Control
          name="answer"
          onChange={handlechange}
          type="text"
          value={answer}
        ></Form.Control>
        <span style={{ color: "red" }}>{answerErr}</span>
      </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
