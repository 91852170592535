import {
  addaddressApi,
  getTaddressApi,
  deleteaddressApi,
  getWalletAddressApi,
} from "../Components/constant/Api";
import { myApi } from "./api";

export const addressApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getaddress: builder.query({
      query: () => ({
        url: getTaddressApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
      providesTags: (_) => ["address"],
    }),
    getWalletAddress: builder.query({
      query: () => ({
        url: getWalletAddressApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
      providesTags: (_) => ["address"],
    }),

    setaddress: builder.mutation({
      query: (post) => ({
        url: addaddressApi,
        method: "POST",
        body: post,
      }),
      invalidatesTags: (_) => ["address", "dashboard","address"],
    }),

    deleteaddress: builder.mutation({
      query: (post) => ({
        url: deleteaddressApi + "/" + post.id + "/" + post.type,
        method: "DELETE",
      }),
      invalidatesTags: (_) => ["address", "dashboard","address"],
    }),
  }),
});

export const {
  useGetaddressQuery,
  useGetWalletAddressQuery,
  useSetaddressMutation,
  useDeleteaddressMutation,
} = addressApi;
