import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  useGetTokenSaleTimeQuery,
  useSetTokenSaleTimeMutation,
  useUpdateStatusTokenSaleTimeMutation,
} from "../../redux/tokensaletimeApi";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
function TokenSaleTime() {
  const [setTokenSaleTime] = useSetTokenSaleTimeMutation();
  const [updateStatusTokenSaleTime] = useUpdateStatusTokenSaleTimeMutation();
  const { data } = useGetTokenSaleTimeQuery();
  const [dateTime, setDateTime] = useState(
    new Date().toISOString().slice(0, 16)
  );
  const [timer_text, setTimer_text] = useState("");

  useEffect(() => {
    if (data) {
      let date = new Date(data[0]?.date_time).toISOString().slice(0, 16);
      setDateTime(date);
      setTimer_text(data[0]?.timer_text);
    }
  }, [data]);

  const handlechange = (e) => {
    if (e.target.name == "startDateTime") {
      setDateTime(e.target.value);
    } else {
      setTimer_text(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const sendDate = {
      date_time: dateTime,
      timer_text,
    };
    setTokenSaleTime(sendDate).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };
  const updateAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => UpdateStatus(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const UpdateStatus = async (id) => {
    let data = {
      id,
    };
    updateStatusTokenSaleTime(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      }
    });
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Time</b>
              </h2>
            </div>

            <div className="row">
              <div className="col-md-5 m-auto text-center mt-md-5">
                <div className="product-list-outer card p-3 p-md-4">
                  <form onSubmit={handleSubmit}>
                    <textarea
                      name="timer_text"
                      onChange={handlechange}
                      type="textarea"
                      value={timer_text}
                      className="form-control mb-2"
                    />
                    <input
                      name="startDateTime"
                      onChange={handlechange}
                      type="datetime-local"
                      value={dateTime}
                      min={new Date().toISOString().slice(0, 16)}
                      className="form-control"
                    />
                    <div className="  space-between mt-4">
                      <button
                        type="submit"
                        className="btn btn-primary   ps-5 pe-5 me-3"
                      >
                        Update
                      </button>
                      <button
                        className="btn ps-4 pe-4"
                        style={{
                         
                          background:
                            data && data[0].status === "1"
                              ? "green"
                              : "#d10202",
                        }}
                        onClick={() => {
                          updateAlert(data && data[0].id);
                        }}
                        title={
                          data && data[0].status === "1" ? "Inactive" : "Active"
                        }
                      >
                        <i className="fa fa-lock" style={{ color: "#fff" }}></i> Show/Hide
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TokenSaleTime;
