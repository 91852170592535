import React, { useState } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useGetRoadmapQuery } from "../../redux/roadmapApi";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export const RoadMap = () => {
  const { data: record } = useGetRoadmapQuery();

  const [id, setId] = useState(record&&record[0]?.id);

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "roadmap",
      text: "Roadmap",
      className: "role",
      align: "left",
      sortable: true,
    },
    {
      key: "phase1",
      text: "Phase1",
      className: "image",
      align: "left",
      sortable: true,
    },
    {
      key: "phase2",
      text: "Phase2",
      className: "image",
      align: "left",
      sortable: true,
    },
    {
      key: "phase3",
      text: "Phase3",
      className: "image",
      align: "left",
      sortable: true,
    },
    {
      key: "phase4",
      text: "Phase4",
      className: "image",
      align: "left",
      sortable: true,
    },
    {
      key: "phase5",
      text: "Phase5",
      className: "image",
      align: "left",
      sortable: true,
    },
    {
      key: "phase6",
      text: "Phase6",
      className: "image",
      align: "left",
      sortable: true,
    },

    {
      key: "createdAt",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record?.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },

    // {
    //   key: "action",
    //   text: "Action",
    //   className: "Action",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     return (
    //       <>
    //         <button onClick={() => deleteAlert(record?.id)} title="Delete team">
    //           <span className="mdi mdi-trash-can-outline"></span>
    //         </button>
    //         <button onClick={() => edit(record)} title="Update team">
    //           <span className="mdi mdi-square-edit-outline"></span>
    //         </button>
    //       </>
    //     );
    //   },
    // },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>RoadMap List</b>
              </h2>
              <div className="add-product-btn ">
                <Link
                  className="btn btn-primary"
                  to={`/add-roadmap/${id ? id : ""}`}
                >
                  Add/Update RoadMap
                </Link>
              </div>
            </div>

            <div className="product-list-outer card p-3">
              <ReactDatatable
                config={config}
                records={record}
                columns={columns}
              />
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};
