import React, { useState,useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ImageValid } from "../../validations/ImageValid";
import { InputValid } from "../../validations/InputValid";
import { toast } from "react-toastify";
import {  useUpdateTeamMutation } from "../../../redux/teamApi";

function UpdateTeamModal(props) {
  const [updateTeam] = useUpdateTeamMutation();
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [role, setRole] = useState("");
  const [roleErr, setRoleErr] = useState("");
  const [imageURL, setImageUrl] = useState("");
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [id, setId] = useState("")

  useEffect(() => {
     if(props.currentRecord){
        setName(props.currentRecord.name)
        setRole(props.currentRecord.role)
        setImageUrl(props.currentRecord.image)
        setId(props.currentRecord.id)
     }
  }, [props])
  

  const handleClose = () => {
    setName("");
    setImage("");
    setNameErr("");
    setImageErr("");
    setRoleErr("");
    setRole("");
    props.setUpdateShow(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "name") {
      setName(value);
      const err = InputValid(name, value);
      setNameErr(err);
    }
    if (name === "role") {
      setRole(value);
      const err = InputValid(name, value);
      setRoleErr(err);
    }
  };
  const handlechangeimage = (e) => {
    let { name } = e.target;

    const image = e.target.files[0];
    if (name === "teamimage") {
      setImage(image);
      setImageUrl(URL.createObjectURL(image));
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };
  const onsubmit = async (e) => {
    const checkName = InputValid("name", name);
    if (checkName) {
      setNameErr(checkName);
      return false;
    }
    const checkrole = InputValid("role", role);
    if (checkrole) {
      setRoleErr(checkrole);
      return false;
    }
    let checkImage = ImageValid("teamimage", image);
    if (checkImage) {
      setImageErr(checkImage);
      return false;
    }

    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("role", role);
    formdata.append("team_image", image);
    formdata.append("id", id);
    updateTeam(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };
  return (
    <div>
      <Modal show={props.updateShow} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Add Team</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Name </Form.Label>
              <Form.Control
                name="name"
                onChange={handlechange}
                type="text"
                value={name}
              ></Form.Control>
              <span style={{ color: "red" }}>{nameErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Role </Form.Label>
              <Form.Control
                name="role"
                onChange={handlechange}
                type="text"
                value={role}
              ></Form.Control>
              <span style={{ color: "red" }}>{roleErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Team Image</Form.Label>
              
              <Form.Control
                type="file"
                name="teamimage"
                onChange={handlechangeimage}
                accept=".png, .jpg, .jpeg"
              />
              <span style={{ color: "red" }}>{imageErr}</span>
            </Form.Group>
            <img
                style={{ width: "100px" }}
                src={imageURL}
                className="img-fluid"
                alt=""
              />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UpdateTeamModal;
