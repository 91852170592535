import {
    addGamingApi,
    deleteGamingApi,
    getGamingApi,
  
    
  } from "../Components/constant/Api";
  import { myApi } from "./api";
  
  export const gamingApi = myApi.injectEndpoints({
    endpoints: (builder) => ({
      getGaming: builder.query({
        query: () => ({
          url: getGamingApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? "" : "";
        },
        providesTags: (_) => ["gaming"],
      }),
      setGaming: builder.mutation({
        query: (post) => ({
          url: addGamingApi,
          method: "POST",
          body: post,
        }),
        invalidatesTags: (_) => ["gaming", "dashboard"],
      }),
      deleteGaming: builder.mutation({
        query: (post) => ({
          url: deleteGamingApi + "/" + post.id,
          method: "DELETE",
        }),
        invalidatesTags: (_) => ["gaming", "dashboard"],
      }),
   
    }),
  });
  
  export const {
    useGetGamingQuery,
    useSetGamingMutation,
    useDeleteGamingMutation,
 
  } = gamingApi;