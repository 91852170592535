import { getDocumentApi,addDocumentApi } from "../Components/constant/Api";
import { myApi } from "./api";

export const DocumentApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocument: builder.query({
      query: () => ({
        url: getDocumentApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
      providesTags: (_) => ["document"],
    }),
    setDocument: builder.mutation({
      query: (post) => ({
        url: addDocumentApi,
        method: "POST",
        body: post,
      }),
      invalidatesTags: (_) => ["document"],
    }),
  }),
});

export const { useGetDocumentQuery, useSetDocumentMutation } = DocumentApi;