import {
  addVideoApi,
  deleteVideoApi,
  getVideoApi,
  updateVideoApi,
  updateVideoStatusApi,
} from "../Components/constant/Api";
import { myApi } from "./api";

export const videoApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getVideo: builder.query({
      query: () => ({
        url: getVideoApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
      providesTags: (_) => ["video"],
    }),
    setVideom: builder.mutation({
      query: (post) => ({
        url: addVideoApi,
        method: "POST",
        body: post,
      }),
      invalidatesTags: (_) => ["video", "dashboard"],
    }),
    deleteVideo: builder.mutation({
      query: (post) => ({
        url: deleteVideoApi + "/" + post.id,
        method: "DELETE",
      }),
      invalidatesTags: (_) => ["video", "dashboard"],
    }),
    updateStatusVideo: builder.mutation({
      query: (post) => ({
        url: updateVideoStatusApi + "/" + post.id,
        method: "PUT",
      }),
      invalidatesTags: (_) => ["video"],
    }),
    updateVideo: builder.mutation({
      query: (post) => ({
        url: updateVideoApi,
        method: "PUT",
        body:post
      }),
      invalidatesTags: (_) => ["video"],
    }),
  }),
});

export const {useGetVideoQuery,useSetVideomMutation,useUpdateStatusVideoMutation,useDeleteVideoMutation,useUpdateVideoMutation} = videoApi;
