import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { InputValid } from "../../validations/InputValid";
import { toast } from "react-toastify";
import { useSetaddressMutation } from "../../../redux/addressApi";

export const AddAddressModal = (props) => {
  const [setAddress] = useSetaddressMutation();
  const [smart_contract_address, setsmart_contract_address] = useState("");
  const [smart_contract_addressErr, setSmart_contract_addressErr] =
    useState("");
  const [wallet_address, setwallet_address] = useState("");
  const [wallet_addressErr, setwallet_addressErr] = useState("");
  const handleClose = () => {
    setsmart_contract_address("");
    setSmart_contract_addressErr("");
    setwallet_address("");
    setwallet_addressErr("");
    props.setShow(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "smart_contract_address") {
      setsmart_contract_address(value);
      const err = InputValid(name, value);
      setSmart_contract_addressErr(err);
    }
    if (name === "wallet_address") {
      setwallet_address(value);
      const err = InputValid(name, value);
      setwallet_addressErr(err);
    }
  };

  const onsubmit = async (e) => {
    const checkQuestion = InputValid(
      "smart_contract_address",
      smart_contract_address
    );
    if (checkQuestion) {
      setSmart_contract_addressErr(checkQuestion);
      return false;
    }
    // const checkAnswer = InputValid("wallet_address", wallet_address);
    // if (checkAnswer) {
    //   setwallet_addressErr(checkAnswer);
    //   return false;
    // }

    const data = {
      type: "Contract",
      smart_contract_address,

      wallet_address,
    };
    setAddress(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Add Address</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Smart Contract Address </Form.Label>
              <Form.Control
                name="smart_contract_address"
                onChange={handlechange}
                type="text"
                value={smart_contract_address}
              ></Form.Control>
              <span style={{ color: "red" }}>{smart_contract_addressErr}</span>
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Answer </Form.Label>
              <Form.Control
                name="answer"
                onChange={handlechange}
                type="text"
                value={answer}
              ></Form.Control>
              <span style={{ color: "red" }}>{answerErr}</span>
            </Form.Group> */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
