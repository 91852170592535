import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ImageValid } from "../../validations/ImageValid";
import { InputValid } from "../../validations/InputValid";
import { toast } from "react-toastify";
import { useUpdatePartnershipMutation } from "../../../redux/partnershipApi";

function UpdatePartnerShipModal(props) {
  const [updatePartnership] = useUpdatePartnershipMutation();
  const [brandName, setBrandName] = useState("");
  const [BrandNameErr, setBrandNameErr] = useState("");
  const [link, setLink] = useState("");
  const [linkErr, setLinkErr] = useState("");
  const [imageURL, setImageUrl] = useState("");
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    if (props.currentRecord) {
      setBrandName(props.currentRecord.brand_name);
      setLink(props.currentRecord.link);
      setImageUrl(props.currentRecord.image);
      setId(props.currentRecord.id);
    }
  }, [props]);

  const handleClose = () => {
    setBrandName("");
    setImage("");
    setBrandNameErr("");
    setImageErr("");
    setLink("");
    setLinkErr("");
    props.setUpdateShow(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "brandName") {
      setBrandName(value);
      const err = InputValid(name, value);
      setBrandNameErr(err);
    }
    if (name === "link") {
      setLink(value);
      const err = InputValid(name, value);
      setLinkErr(err);
    }
  };
  const handlechangeimage = (e) => {
    let { name } = e.target;

    const image = e.target.files[0];
    if (name === "partnershipimage") {
      setImage(image);
      setImageUrl(URL.createObjectURL(image));
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };
  const onsubmit = async (e) => {
    const checkName = InputValid("brandName", brandName);
    if (checkName) {
      setBrandNameErr(checkName);
      return false;
    }
    const checkLink = InputValid("link", link);
    if (checkLink) {
      setLinkErr(checkLink);
      return false;
    }
    let checkImage = ImageValid("partnershipimage", image);
    if (checkImage) {
      setImageErr(checkImage);
      return false;
    }

    const formdata = new FormData();
    formdata.append("brand_name", brandName);
    formdata.append("link", link);
    formdata.append("id", id);
    formdata.append("partnership_image", image);
    updatePartnership(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };
  return (
    <div>
      <Modal show={props.updateShow} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Add Partnership</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Brand Name </Form.Label>
              <Form.Control
                name="brandName"
                onChange={handlechange}
                type="text"
                value={brandName}
              ></Form.Control>
              <span style={{ color: "red" }}>{BrandNameErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Link </Form.Label>
              <Form.Control
                name="link"
                onChange={handlechange}
                type="url"
                value={link}
              ></Form.Control>
              <span style={{ color: "red" }}>{linkErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Partnership Image</Form.Label>

              <Form.Control
                type="file"
                name="partnershipimage"
                onChange={handlechangeimage}
                accept=".png, .jpg, .jpeg"
              />
              <span style={{ color: "red" }}>{imageErr}</span>
            </Form.Group>
            <img
              style={{ width: "100px" }}
              src={imageURL}
              className="img-fluid"
              alt=""
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UpdatePartnerShipModal;
