import React, { useState } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import {
  useDeleteVideoMutation,
  useGetVideoQuery,
  useUpdateStatusVideoMutation,
} from "../../redux/videoApi";
import AddVideoModal from "../partial/video/AddVideoModal";
import UpdateVideoModal from "../partial/video/UpdateVideoModal";

function Video() {
  const { data: record } = useGetVideoQuery();
  const [updateStatusVideo] = useUpdateStatusVideoMutation();
  const [deleteVideo] = useDeleteVideoMutation();
  const [show, setShow] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "video",
      text: "Video",
      className: "image",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
          <video width="320" height="240" controls>
           <source src={record.video} type="video/mp4"/>
           </video>
          </>
        );
      },
    },

    {
      key: "createdAt",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
      key: "status",
      text: "Status",
      className: "Action ",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              style={{
                border: "1px solid #fff",
                borderRadius: "3px",
                background: record.status === "1" ? "green" : "#d10202",
              }}
              onClick={() => {
                updateAlert(record.id);
              }}
              title={record.status === "1" ? "Inactive" : "Active"}
            >
              <i className="fa fa-lock" style={{ color: "#fff" }}></i>
            </button>
          </>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button onClick={() => deleteAlert(record.id)} title="Delete team">
              <span className="mdi mdi-trash-can-outline"></span>
            </button>
            <button onClick={() => edit(record)} title="Update team">
              <span className="mdi mdi-square-edit-outline"></span>
            </button>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const updateAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => UpdateStatus(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const UpdateStatus = async (id) => {
    let data = {
      id,
    };
    updateStatusVideo(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      }
    });
  };

  const deleteAlert = (Deleteid) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => videoDelete(Deleteid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const videoDelete = async (id) => {
    let data = {
      id,
    };
    deleteVideo(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      }
    });
  };

  const handleShow = (id) => {
    setShow(true);
  };

  const edit = (record) => {
    setUpdateShow(true);
    setCurrentRecord(record);
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Video List</b>
              </h2>
              <div className="add-product-btn ">
                <button
                  className="btn btn-primary"
                  onClick={() => handleShow()}
                >
                  Add Video
                </button>
              </div>
            </div>

            <div className="product-list-outer card p-3">
              <ReactDatatable
                config={config}
                records={record}
                columns={columns}
              />
            </div>
            <AddVideoModal show={show} setShow={setShow} />
            <UpdateVideoModal
              updateShow={updateShow}
              setUpdateShow={setUpdateShow}
              currentRecord={currentRecord}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Video;
