import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { InputValid } from "../../validations/InputValid";
import { toast } from "react-toastify";

import {useSetDocumentMutation } from "../../../redux/documentApi";

export const UpdateDocument = (props) => {
    const [setDocument] = useSetDocumentMutation();
  const [url, seturl] = useState("");
  const [urlErr, seturlErr] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    if (props.currentRecord) {
      seturl(props.currentRecord.url);

      setId(props.currentRecord.id);
    }
  }, [props]);
  const handleClose = () => {
    seturl("");

    seturlErr("");

    props.setUpdateShow(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "url") {
      seturl(value);
      const err = InputValid(name, value);
      seturlErr(err);
    }
  };

  const onsubmit = async (e) => {
    const checkQuestion = InputValid("url", url);
    if (checkQuestion) {
      seturlErr(checkQuestion);
      return false;
    }

    const data = {
      url,
      id,
    };
    setDocument(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };
  return (
    <>
      <Modal show={props.updateShow} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Update Url</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Url </Form.Label>
              <Form.Control
                name="url"
                onChange={handlechange}
                type="text"
                value={url}
              ></Form.Control>
              <span style={{ color: "red" }}>{urlErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
