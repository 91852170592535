import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { InputValid } from "../../validations/InputValid";
import { toast } from "react-toastify";
import { useSetAboutMutation } from "../../../redux/aboutUsApi";

export const AddAbouUs = (props) => {
  const [setAbout] = useSetAboutMutation();
  const [about_us, setabout_us] = useState("");
  const [about_usErr, setabout_usErr] = useState("");
  const [wallet_address, setwallet_address] = useState("");
  const [wallet_addressErr, setwallet_addressErr] = useState("");
  const handleClose = () => {
    setabout_us("");
    setabout_usErr("");
    setwallet_address("");
    setwallet_addressErr("");
    props.setShow(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "about_us") {
      setabout_us(value);
      const err = InputValid(name, value);
      setabout_usErr(err);
    }
  };

  const onsubmit = async (e) => {
    const checkQuestion = InputValid("about_us", about_us);
    if (checkQuestion) {
      setabout_usErr(checkQuestion);
      return false;
    }
    // const checkAnswer = InputValid("wallet_address", wallet_address);
    // if (checkAnswer) {
    //   setwallet_addressErr(checkAnswer);
    //   return false;
    // }

    const data = {
      about_us,
    };
    setAbout(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };
  return (
    <>
      {" "}
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Add About Us</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> About Us </Form.Label>
              <Form.Control
                name="about_us"
                onChange={handlechange}
                type="text"
                value={about_us}
              ></Form.Control>
              <span style={{ color: "red" }}>{about_usErr}</span>
            </Form.Group>
            
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
