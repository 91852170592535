import React, { useState } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import {
  useGetaddressQuery,
  useDeleteaddressMutation,
} from "../../redux/addressApi";
import { AddAddressModal } from "../partial/address/AddAddressModal";
import { UpdateAddressModal } from "../partial/address/UpdateAddressModal";

export const SmartContract = () => {
  const { data: record } = useGetaddressQuery();

  const [deleteaddress] = useDeleteaddressMutation();
  const [show, setShow] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "smart_contract_address",
      text: "Smart Contract Address",
      className: "smart_contract_address",
      align: "left",
      sortable: true,
    },

    {
      key: "createdAt",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },

    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        console.log(record.id,"record");
        return (
          <>
            <button
              onClick={() => deleteAlert(record.id)}
              title="Delete banner"
            >
              <span className="mdi mdi-trash-can-outline"></span>
            </button>
            <button onClick={() => edit(record)} title="Update">
              <span className="mdi mdi-square-edit-outline"></span>
            </button>
          </>
        );
      },
    },
  ];

  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };

  const deleteAlert = (Deleteid) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => addressDelete(Deleteid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const addressDelete = async (id) => {
    let data = {
      id,
      type:"Contract"
    };
   
    deleteaddress(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      }
    });
  };

  const handleShow = (id) => {
    setShow(true);
  };

  const edit = (record) => {
    setUpdateShow(true);
    setCurrentRecord(record);
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>Address List</b>
                </h2>
                <div className="add-product-btn ">
                  <button
                    className="btn btn-primary"
                    onClick={() => handleShow()}
                  >
                    Add Address
                  </button>
                </div>
              </div>

              <div className="product-list-outer card p-3">
                <ReactDatatable
                  config={config}
                  records={record}
                  columns={columns}
                />
              </div>
             <AddAddressModal show={show} setShow={setShow} />
            <UpdateAddressModal updateShow={updateShow} setUpdateShow={setUpdateShow} currentRecord={currentRecord}/> 
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
