export const socialMediaEnum=[
    {
        key:"FaceBook",
        value:"bi bi-facebook"
    },
    {
        key:"Twitter",
        value:"bi bi-twitter"
    },
    {
        key:"Instagram",
        value:"bi bi-instagram"
    },
    {
        key:"Youtube",
        value:"bi bi-youtube"
    },
    {
        key:"Skype",
        value:"bi bi-skype"
    },
    {
        key:"Linkedin",
        value:"bi bi-linkedin"
    },
    {
        key:"Telegram",
        value:"bi bi-telegram"
    },
    {
        key:"Discord",
        value:"bi bi-discord"
    },
]