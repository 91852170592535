import React from "react";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useGetDashboardQuery } from "../../redux/dashboardApi";

function Dashboard() {
  const { data, isLoading } = useGetDashboardQuery();

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid p-0 mt-2">
            <div className="row dashbord_man">
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/team">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Team</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                        <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                data?.team
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-1">
                              <span className="mdi mdi-account-group"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/faq">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total FAQ</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                        <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                data?.faq
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-1">
                              <span className="mdi mdi-frequently-asked-questions"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/announcement">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Announcement</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                        <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                data?.announcement
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-1">
                              <span className="mdi mdi-bullhorn"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/whitepaper">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Whitepaper</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                        <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                data?.whitepaper
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-1">
                              <span className="mdi mdi-newspaper-variant"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/partnership">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Partnership</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                        <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                data?.partnership
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-1">
                              <span className="mdi mdi-handshake"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 col-12 card-space">
                <div className="card card-animate">
                  <Link to="/video">
                    <div className="card-body">
                      <p className="fw-medium mb-0">Total Video</p>
                      <div className="d-flex justify-content-between count-outer">
                        <div>
                        <h2 className="ff-secondary fw-semibold">
                            <span className="counter-value" data-target={100}>
                              {isLoading ? (
                                <div
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              ) : (
                                data?.video
                              )}
                            </span>
                          </h2>
                        </div>
                        <div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title rounded-circle fs-1">
                              <span className="mdi mdi-video"></span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
export default Dashboard;
