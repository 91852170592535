import { addRoadmapApi, getRoadmapApi } from "../Components/constant/Api";
import { myApi } from "./api";

export const roadmapApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoadmap: builder.query({
      query: () => ({
        url: getRoadmapApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
      providesTags: (_) => ["roadmap"],
    }),
    setRoadmap: builder.mutation({
      query: (post) => ({
        url: addRoadmapApi,
        method: "POST",
        body: post,
      }),
      invalidatesTags: (_) => ["roadmap"],
    }),
  }),
});

export const { useGetRoadmapQuery, useSetRoadmapMutation } = roadmapApi;
