import {
  addPartnershipApi,
  deletePartnershipApi,
  getPartnershipApi,
  updatePartnershipApi,
  updatePartnershipStatusApi,
} from "../Components/constant/Api";
import { myApi } from "./api";

export const partnershipApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getPartnership: builder.query({
      query: () => ({
        url: getPartnershipApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
      providesTags: (_) => ["partnership"],
    }),
    setPartnership: builder.mutation({
      query: (post) => ({
        url: addPartnershipApi,
        method: "POST",
        body: post,
      }),
      invalidatesTags: (_) => ["partnership","dashboard"],
    }),
    deletePartnership: builder.mutation({
      query: (post) => ({
        url: deletePartnershipApi + "/" + post.id,
        method: "DELETE",
      }),
      invalidatesTags: (_) => ["partnership","dashboard"],
    }),
    updateStatusPartnership: builder.mutation({
      query: (post) => ({
        url: updatePartnershipStatusApi + "/" + post.id,
        method: "PUT",
      }),
      invalidatesTags: (_) => ["partnership"],
    }),
    updatePartnership: builder.mutation({
      query: (post) => ({
        url: updatePartnershipApi,
        method: "PUT",
        body:post
      }),
      invalidatesTags: (_) => ["partnership"],
    }),
  }),
});

export const {
  useGetPartnershipQuery,
  useSetPartnershipMutation,
  useDeletePartnershipMutation,
  useUpdateStatusPartnershipMutation,
  useUpdatePartnershipMutation
} = partnershipApi;
