import { baseUrl } from "./BaseUrl";

export const loginApi = baseUrl + "/login";
export const changePasswordApi = baseUrl + "/change-password";

//===================== Dashboard ================
export const getDashboardApi = "/dashboard";

//===================== team ======================
export const addTeamApi = "/add-team";
export const getTeamApi = "/get-team";
export const deleteTeamApi = "/delete-team";
export const updateTeamStatusApi = "/update-team-status";
export const updateTeamApi = "/update-team";

//================= Announcement ===================
export const addAnnouncementApi = "/add-accouncement";
export const getAnnouncementApi = "/get-accouncement";
export const deleteAnnouncementApi = "/delete-accouncement";
export const updateAnnouncementStatusApi = "/update-accouncement-status";
export const updateAnnouncementApi = "/update-accouncement";

//================= Video ===================
export const addVideoApi = "/add-video";
export const getVideoApi = "/get-video";
export const deleteVideoApi = "/delete-video";
export const updateVideoStatusApi = "/update-video-status";
export const updateVideoApi = "/update-video";

//================= Faq ===================
export const addFaqApi = "/add-faq";
export const getFaqApi = "/get-faq";
export const deleteFaqApi = "/delete-faq";
export const updateFaqStatusApi = "/update-faq-status";
export const updateFaqApi = "/update-faq";

//================= WhitePaper ===================
export const addWhitePaperApi = "/add-whitepaper";
export const getWhitePaperApi = "/get-whitepaper";
export const deleteWhitePaperApi = "/delete-whitepaper";
export const updateWhitePaperStatusApi = "/update-whitepaper-status";

//================= Partnership ===================
export const addPartnershipApi = "/add-partnership";
export const getPartnershipApi = "/get-partnership";
export const deletePartnershipApi = "/delete-partnership";
export const updatePartnershipStatusApi = "/update-partnership-status";
export const updatePartnershipApi = "/update-partnership";

//================= SideBar ===================
export const addSideBarApi = "/add-sidebar";
export const getSideBarApi = "/get-sidebar";
export const deleteSideBarApi = "/delete-sidebar";
export const updateSideBarStatusApi = "/update-sidebar-status";
export const updateSideBarApi = "/update-sidebar";

//===================== Token Sale time ===================
export const addTokenSaleTimeApi = "/add-tokensaletime";
export const getTokenSaleTimeApi = "/get-tokensaletime";
export const updateTokenSaleTimeStatusApi = "/update-tokensaletime-status";

// ======================== aaddrerss =================

export const addaddressApi = "/add-update-address";
export const getTaddressApi = "/get-address";
export const getWalletAddressApi = "/get-wallet-address";
export const deleteaddressApi = "/delete-address";

// ===========================about us ==============//
export const addAboutUsApi = "/add-update-about-us";
export const getAboutUsApi = "/get-about-us";
export const deleteAboutUsApi = "/delete-about-us";
// ===========================Ai ==============//
export const addAiApi = "/add-update-ai";
export const getAiApi = "/get-ai";
export const deleteAiApi = "/delete-ai";
// ===========================deso ==============//
export const addDesoApi = "/add-update-deso";
export const getDesoApi = "/get-deso";
export const deleteDesoApi = "/delete-deso";

// ===========================Gaming ==============//
export const addGamingApi = "/add-update-gaming";
export const getGamingApi = "/get-gaming";
export const deleteGamingApi = "/delete-gaming";

// =========================Vision ================

export const addVisionApi = "/add-vision";
export const getVisionApi = "/get-vision";

// =========================Mission ================

export const addMissionApi = "/add-mission";
export const getMissionApi = "/get-mission";


//================= Social Media =================== 
export const addSocialMediaApi="/add-socialmedia"
export const getSocialMediaApi="/get-socialmedia"
export const deleteSocialMediaApi="/delete-socialmedia"
export const updateSocialMediaApi="/update-socialmedia"

// =========================Mission ================

export const addRoadmapApi = "/add-roadmap";
export const getRoadmapApi = "/get-roadmap";

// ================document====================

export const addDocumentApi = "/add-document";
export const getDocumentApi = "/get-document";








