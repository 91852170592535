import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useSetWhitepaperMutation } from "../../../redux/whitepaperApi";
import { PdfValid } from "../../validations/PdfValid";

function AddWhitePaperModal(props) {
  const [setWhitepaper] = useSetWhitepaperMutation();
  const [pdf, setPdf] = useState("");
  const [pdfErr, setPdfErr] = useState(false);
  const handleClose = () => {
    setPdf("");
    setPdfErr("");
    props.setShow(false);
  };

  const handlechangeimage = (e) => {
    let { name } = e.target;

    const pdfValue = e.target.files[0];
    if (name === "pdf") {
      setPdf(pdfValue);
      let checkPdf = PdfValid(name, pdfValue);
      setPdfErr(checkPdf);
    }
  };
  const onsubmit = async (e) => {
    let checkPdf = PdfValid("pdf", pdf);
    if (checkPdf) {
      setPdfErr(checkPdf);
      return false;
    }

    const formdata = new FormData();
    formdata.append("pdf", pdf);
    setWhitepaper(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };
  return (
    <div>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Add WhitePaper</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>White Paper</Form.Label>
              <Form.Control
                type="file"
                name="pdf"
                onChange={handlechangeimage}
                accept=".pdf"
              />
              <span style={{ color: "red" }}>{pdfErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddWhitePaperModal;
