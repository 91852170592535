import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { InputValid } from "../../validations/InputValid";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import {
  useSetRoadmapMutation,
  useGetRoadmapQuery,
} from "../../../redux/roadmapApi";
import { ImageValid } from "../../validations/ImageValid";
import Header from "../../Widgets/Header";
import Navbar from "../../Widgets/Navbar";

export const AddRoadmap = (props) => {
  const { id } = useParams();

  const navigate = useNavigate();
  const { data: record } = useGetRoadmapQuery();
  const [setroadmap] = useSetRoadmapMutation();
  const [roadmap, setroadmaps] = useState("");
  const [roadmapErr, setroadmapErr] = useState("");

  const [phase1, setphase1] = useState("");

  const [phase1Err, setphase1Err] = useState("");
  const [phase1_month_yearErr, setphase1_month_yearErr] = useState("");
  const [phase1_month_year, setphase1_month_year] = useState("");

  const [phase2_month_yearErr, setphase2_month_yearErr] = useState("");
  const [phase2_month_year, setphase2_month_year] = useState("");

  const [phase3_month_yearErr, setphase3_month_yearErr] = useState("");
  const [phase3_month_year, setphase3_month_year] = useState("");

  const [phase4_month_yearErr, setphase4_month_yearErr] = useState("");
  const [phase4_month_year, setphase4_month_year] = useState("");

  const [phase5_month_yearErr, setphase5_month_yearErr] = useState("");
  const [phase5_month_year, setphase5_month_year] = useState("");
  const [phase6_month_yearErr, setphase6_month_yearErr] = useState("");
  const [phase6_month_year, setphase6_month_year] = useState("");

  const [phase2, setphase2] = useState("");
  const [phase2Err, setphase2Err] = useState("");
  const [phase3, setphase3] = useState("");
  const [phase3Err, setphase3Err] = useState("");
  const [phase4, setphase4] = useState("");
  const [phase4Err, setphase4Err] = useState("");
  const [phase5, setphase5] = useState("");
  const [phase5Err, setphase5Err] = useState("");
  const [phase6, setphase6] = useState("");
  const [phase6Err, setphase6Err] = useState("");

  const [ids, setid] = useState("");
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "roadmap") {
      setroadmaps(value);
      const err = InputValid(name, value);
      setroadmapErr(err);
    }
    if (name === "phase1") {
      setphase1(value);
      const err = InputValid(name, value);
      setphase1Err(err);
    }
    if (name === "phase2") {
      setphase2(value);
      const err = InputValid(name, value);
      setphase2Err(err);
    }
    if (name === "phase3") {
      setphase3(value);
      const err = InputValid(name, value);
      setphase3Err(err);
    }
    if (name === "phase1") {
      setphase1(value);
      const err = InputValid(name, value);
      setphase1Err(err);
    }
    if (name === "phase4") {
      setphase4(value);
      const err = InputValid(name, value);
      setphase4Err(err);
    }
    if (name === "phase5") {
      setphase5(value);
      const err = InputValid(name, value);
      setphase5Err(err);
    }
    if (name === "phase6") {
      setphase6(value);
      const err = InputValid(name, value);
      setphase6Err(err);
    }

    if (name === "phase1_month_year") {
      setphase1_month_year(value);
      const err = InputValid(name, value);
      setphase1_month_yearErr(err);
    }
    if (name === "phase2_month_year") {
      setphase2_month_year(value);
      const err = InputValid(name, value);
      setphase2_month_yearErr(err);
    }
    if (name === "phase3_month_year") {
      setphase3_month_year(value);
      const err = InputValid(name, value);
      setphase3_month_yearErr(err);
    }

    if (name === "phase4_month_year") {
      setphase4_month_year(value);
      const err = InputValid(name, value);
      setphase4_month_yearErr(err);
    }
    if (name === "phase5_month_year") {
      setphase5_month_year(value);
      const err = InputValid(name, value);
      setphase5_month_yearErr(err);
    }
    if (name === "phase6_month_year") {
      setphase6_month_year(value);
      const err = InputValid(name, value);
      setphase6_month_yearErr(err);
    }
  };

  useEffect(() => {
    if (record) {
      const {
        roadmap,
        phase1,
        phase1_month_year,
        phase2,
        phase2_month_year,
        phase3,
        phase3_month_year,
        phase4,
        phase4_month_year,
        phase5,
        phase5_month_year,
        phase6,
        phase6_month_year,
        id,
      } = record[0];
      setroadmaps(roadmap);
      setphase1(phase1);
      setphase1_month_year(phase1_month_year);

      setphase2(phase2);
      setphase2_month_year(phase2_month_year);

      setphase3(phase3);
      setphase3_month_year(phase3_month_year);

      setphase4(phase4);
      setphase4_month_year(phase4_month_year);
      setphase5(phase5);
      setphase5_month_year(phase5_month_year);

      setphase6(phase6);
      setphase6_month_year(phase6_month_year);
      setid(id);
    }
  }, [record]);
  const onSubmit = async (e) => {
    const checkQuestion = InputValid("roadmap", roadmap);
    if (checkQuestion) {
      setroadmapErr(checkQuestion);
      return false;
    }
    const checkQuestion1 = InputValid("phase1", phase1);
    if (checkQuestion1) {
      setphase1Err(checkQuestion1);
      return false;
    }
    const checkQuestions = InputValid("phase1_month_year", phase1_month_year);
    if (checkQuestions) {
      setphase1_month_yearErr(checkQuestions);
      return false;
    }
    const checkQuestion2 = InputValid("phase2", phase2);
    if (checkQuestion2) {
      setphase2Err(checkQuestion2);
      return false;
    }
    const checkQuestion21 = InputValid("phase2_month_year", phase2_month_year);
    if (checkQuestion21) {
      setphase2_month_yearErr(checkQuestion21);
      return false;
    }
    const checkQuestion3 = InputValid("phase3", phase3);
    if (checkQuestion3) {
      setphase3Err(checkQuestion3);
      return false;
    }
    const checkQuestion22 = InputValid("phase3_month_year", phase3_month_year);
    if (checkQuestion22) {
      setphase3_month_yearErr(checkQuestion22);
      return false;
    }

    const checkQuestion4 = InputValid("phase4", phase4);
    if (checkQuestion4) {
      setphase4Err(checkQuestion4);
      return false;
    }
    const checkQuestion23 = InputValid("phase4_month_year", phase4_month_year);
    if (checkQuestion23) {
      setphase4_month_yearErr(checkQuestion23);
      return false;
    }

    const checkQuestion5 = InputValid("phase5", phase5);
    if (checkQuestion5) {
      setphase5Err(checkQuestion5);
      return false;
    }
    const checkQuestion24 = InputValid("phase5_month_year", phase5_month_year);
    if (checkQuestion24) {
      setphase5_month_yearErr(checkQuestion24);
      return false;
    }
    const checkQuestion6 = InputValid("phase6", phase6);
    if (checkQuestion6) {
      setphase6Err(checkQuestion6);
      return false;
    }
    const checkQuestion25 = InputValid("phase6_month_year", phase6_month_year);
    if (checkQuestion25) {
      setphase6_month_yearErr(checkQuestion25);
      return false;
    }
    const roadmapId = id ? id : ids;
    let data = {
      id: roadmapId,
      roadmap,
      phase1,
      phase1_month_year,
      phase2,
      phase2_month_year,
      phase3,
      phase3_month_year,
      phase4,
      phase4_month_year,
      phase5,
      phase5_month_year,
      phase6,
      phase6_month_year,
    };
    setroadmap(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        setTimeout(() => {
          navigate("/roadmap");
        }, 1500);
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b> Add/Update RoadMap </b>
                </h2>
              </div>
              <div className="product-list-outer card p-3">
                <form>
                  <div className="mb-3">
                    <label className="mb-1 d-block">Roadmap</label>
                    <input
                      name="roadmap"
                      onChange={handleChange}
                      type="text"
                      value={roadmap}
                      className="form-control"
                    />
                    <span style={{ color: "red" }}>{roadmapErr}</span>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="mb-1 d-block">Phase 1</label>
                        <input
                          name="phase1"
                          onChange={handleChange}
                          type="text"
                          value={phase1}
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{phase1Err}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="mb-1 d-block">
                          Phase 1 Month Year
                        </label>
                        <input
                          name="phase1_month_year"
                          onChange={handleChange}
                          type="text"
                          value={phase1_month_year}
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>
                          {phase1_month_yearErr}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="mb-1 d-block">Phase 2</label>
                        <input
                          name="phase2"
                          onChange={handleChange}
                          type="text"
                          value={phase2}
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{phase2Err}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="mb-1 d-block">
                          Phase 2 Month Year
                        </label>
                        <input
                          name="phase2_month_year"
                          onChange={handleChange}
                          type="text"
                          value={phase2_month_year}
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>
                          {phase2_month_yearErr}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="mb-1 d-block">Phase 3</label>
                        <input
                          name="phase3"
                          onChange={handleChange}
                          type="text"
                          value={phase3}
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{phase3Err}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="mb-1 d-block">
                          Phase 3 Month Year
                        </label>
                        <input
                          name="phase3_month_year"
                          onChange={handleChange}
                          type="text"
                          value={phase3_month_year}
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>
                          {phase3_month_yearErr}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="mb-1 d-block">Phase 4</label>
                        <input
                          name="phase4"
                          onChange={handleChange}
                          type="text"
                          value={phase4}
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{phase4Err}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="mb-1 d-block">
                          Phase 4 Month Year
                        </label>
                        <input
                          name="phase4_month_year"
                          onChange={handleChange}
                          type="text"
                          value={phase4_month_year}
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>
                          {phase4_month_yearErr}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="mb-1 d-block">Phase 5</label>
                        <input
                          name="phase5"
                          onChange={handleChange}
                          type="text"
                          value={phase5}
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{phase5Err}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="mb-1 d-block">
                          Phase 5 Month Year
                        </label>
                        <input
                          name="phase5_month_year"
                          onChange={handleChange}
                          type="text"
                          value={phase5_month_year}
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>
                          {phase5_month_yearErr}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="mb-1 d-block">Phase 6</label>
                        <input
                          name="phase6"
                          onChange={handleChange}
                          type="text"
                          value={phase6}
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>{phase6Err}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="mb-1 d-block">
                          Phase 6 Month Year
                        </label>
                        <input
                          name="phase6_month_year"
                          onChange={handleChange}
                          type="text"
                          value={phase6_month_year}
                          className="form-control"
                        />
                        <span style={{ color: "red" }}>
                          {phase6_month_yearErr}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={onSubmit}
                    className="btn btn-primary pe-4 ps-4"
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
};
