import React, { useState, useEffect } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";

import { Form, Button } from "react-bootstrap";

import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ImageValid } from "../validations/ImageValid";
import { useGetMissionQuery,useSetMissionMutation  } from "../../redux/MissionApi";

export const Mission = () => {
  const { data: record } = useGetMissionQuery();
  const [setVision] = useSetMissionMutation();

  const [editorData, setEditorData] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [id, setId] = useState("");
  const [imageErr, setImageErr] = useState("");

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  useEffect(() => {
    if (record) {
      setEditorData(record[0]?.mission);
      setImage(record[0]?.image);
      setImageUrl(record[0]?.image);
      setId(record[0]?.id);
    }
  }, [record]);

  const handlechangeimage = (e) => {
    let { name } = e.target;
    const image = e.target.files[0];
    if (name === "image") {
      setImage(image);
      setImageUrl(URL.createObjectURL(image));
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };
  const handleSubmit = async (e) => {
    const formdata = new FormData();
    formdata.append("mission", editorData);
    formdata.append("image", image);
    formdata.append("id", id);

    setVision(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <>
      {" "}
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>Mission </b>
                </h2>
              </div>

              <Form>
                <Form.Group controlId="editor">
                  
                  <CKEditor
                    editor={ClassicEditor}
                    data={editorData}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "link",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "insertTable",
                        "undo",
                        "redo",
                      ],
                    }}
                    onReady={(editor) => {
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={handleEditorChange}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    name="image"
                    onChange={handlechangeimage}
                    // accept=".mp4"
                  />
                  <span style={{ color: "red" }}>{imageErr}</span>
                </Form.Group>
                {imageUrl && (
                  <img className="img-fluid upload_img" src={imageUrl} alt=""></img>
                )}

                <Button variant="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
