import {
  addTeamApi,
  deleteTeamApi,
  getTeamApi,
  updateTeamApi,
  updateTeamStatusApi,
} from "../Components/constant/Api";
import { myApi } from "./api";

export const teamApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getTeam: builder.query({
      query: () => ({
        url: getTeamApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
      providesTags: (_) => ["team"],
    }),
    setTeam: builder.mutation({
      query: (post) => ({
        url: addTeamApi,
        method: "POST",
        body: post,
      }),
      invalidatesTags: (_) => ["team","dashboard"],
    }),
    deleteTeam: builder.mutation({
      query: (post) => ({
        url: deleteTeamApi + "/" + post.id,
        method: "DELETE",
      }),
      invalidatesTags: (_) => ["team","dashboard"],
    }),
    updateStatusTeam: builder.mutation({
        query: (post) => ({
          url: updateTeamStatusApi + "/" + post.id,
          method: "PUT",
        }),
        invalidatesTags: (_) => ["team"],
      }),
      updateTeam: builder.mutation({
        query: (post) => ({
          url: updateTeamApi,
          method: "PUT",
          body:post
        }),
        invalidatesTags: (_) => ["team"],
      }),
  }),
});

export const {
  useGetTeamQuery,
  useSetTeamMutation,
  useDeleteTeamMutation,
  useUpdateStatusTeamMutation,
  useUpdateTeamMutation
} = teamApi;
