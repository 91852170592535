import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ImageValid } from "../../validations/ImageValid";
import { toast } from "react-toastify";
import { useUpdateAnnouncementMutation } from "../../../redux/announcementApi";

function UpdateAnnouncementModal(props) {
  const [updateAnnouncement] = useUpdateAnnouncementMutation();
  const [image, setImage] = useState("");
  const [imageURL, setImageUrl] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    if (props.currentRecord) {
      setImageUrl(props.currentRecord.image);
      setId(props.currentRecord.id);
    }
  }, [props]);

  const handleClose = () => {
    setImage("");
    setImageErr("");
    props.setUpdateShow(false);
  };

  const handlechangeimage = (e) => {
    let { name } = e.target;

    const image = e.target.files[0];
    if (name === "announcementimage") {
      setImage(image);
      setImageUrl(URL.createObjectURL(image));
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };
  const onsubmit = async (e) => {
    let checkImage = ImageValid("announcementimage", image);
    if (checkImage) {
      setImageErr(checkImage);
      return false;
    }

    const formdata = new FormData();
    formdata.append("announcement_image", image);
    formdata.append("id", id);
    updateAnnouncement(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };
  return (
    <div>
      <Modal show={props.updateShow} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Add Announcement</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Announcement Image</Form.Label>
              <Form.Control
                type="file"
                name="announcementimage"
                onChange={handlechangeimage}
                accept=".png, .jpg, .jpeg"
              />
              <span style={{ color: "red" }}>{imageErr}</span>
            </Form.Group>
            <img
              style={{ width: "100px" }}
              src={imageURL}
              className="img-fluid"
              alt=""

            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UpdateAnnouncementModal;
