import { addWhitePaperApi, deleteWhitePaperApi, getWhitePaperApi, updateWhitePaperStatusApi } from "../Components/constant/Api";
  import { myApi } from "./api";
  
  export const whitepaperApi = myApi.injectEndpoints({
    endpoints: (builder) => ({
      getWhitepaper: builder.query({
        query: () => ({
          url: getWhitePaperApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? "" : "";
        },
        providesTags: (_) => ["whitepaper"],
      }),
      setWhitepaper: builder.mutation({
        query: (post) => ({
          url: addWhitePaperApi,
          method: "POST",
          body: post,
        }),
        invalidatesTags: (_) => ["whitepaper","dashboard"],
      }),
      deleteWhitepaper: builder.mutation({
        query: (post) => ({
          url: deleteWhitePaperApi + "/" + post.id,
          method: "DELETE",
        }),
        invalidatesTags: (_) => ["whitepaper","dashboard"],
      }),
      updateStatusWhitepaper: builder.mutation({
          query: (post) => ({
            url: updateWhitePaperStatusApi + "/" + post.id,
            method: "PUT",
          }),
          invalidatesTags: (_) => ["whitepaper"],
        }),
    }),
  });
  
  export const {
    useGetWhitepaperQuery,
    useSetWhitepaperMutation,
    useDeleteWhitepaperMutation,
    useUpdateStatusWhitepaperMutation,
  } = whitepaperApi;
  