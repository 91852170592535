import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { InputValid } from "../../validations/InputValid";
import { toast } from "react-toastify";
import { useSetAiMutation } from "../../../redux/AiApi";
import { ImageValid } from "../../validations/ImageValid";

export const UpdateAi = (props) => {
  const [setAi] = useSetAiMutation();
  const [ai, setai] = useState("");
  const [aiErr, setaiErr] = useState("");
  const [imageURL, setImageUrl] = useState("");
  const [image, setImage] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    if (props.currentRecord) {
      setai(props.currentRecord.ai);
      setImageUrl(props.currentRecord.image);
      setId(props.currentRecord.id);
    }
  }, [props]);
  const handleClose = () => {
    setai("");
    setImage("");
    setaiErr("");
    setImageErr("");

    props.setUpdateShow(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "ai") {
      setai(value);
      const err = InputValid(name, value);
      setaiErr(err);
    }
  };
  const handlechangeimage = (e) => {
    let { name } = e.target;

    const image = e.target.files[0];
    if (name === "aiImage") {
      setImage(image);
      setImageUrl(URL.createObjectURL(image));
      let checkImage = ImageValid(name, image);
      setImageErr(checkImage);
    }
  };

  const onsubmit = async (e) => {
    const checkQuestion = InputValid("ai", ai);
    if (checkQuestion) {
      setaiErr(checkQuestion);
      return false;
    }
    let checkImage = ImageValid("aiImage", image);
    if (checkImage) {
      setImageErr(checkImage);
      return false;
    }

    const formdata = new FormData();
    formdata.append("ai", ai);
    formdata.append("id", id);
    formdata.append("ai_image", image);
    setAi(formdata).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  return (
    <>
      {" "}
      <Modal show={props.updateShow} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Update Ai</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Ai </Form.Label>
              <Form.Control
                name="ai"
                onChange={handlechange}
                type="text"
                value={ai}
              ></Form.Control>
              <span style={{ color: "red" }}>{aiErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Deso Image</Form.Label>

              <Form.Control
                type="file"
                name="aiImage"
                onChange={handlechangeimage}
                accept=".png, .jpg, .jpeg"
              />
              <span style={{ color: "red" }}>{imageErr}</span>
            </Form.Group>
            <img
              style={{ width: "100px" }}
              src={imageURL}
              className="img-fluid"
              alt=""
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
