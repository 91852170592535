import React, { useState } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import { Link } from "react-router-dom";
import moment from "moment";
import ReactDatatable from "@mkikets/react-datatable";
import {
  useGetDocumentQuery,
  useSetDocumentMutation,
} from "../../redux/documentApi";
import { UpdateDocument } from "../partial/document/UpdateDocument";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { AddDocuments } from "../partial/document/AddDocuments";
export const Document = () => {
  const { data: record } = useGetDocumentQuery();

  const [id, setId] = useState(record && record[0]?.id);
  const [show, setShow] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    // {
    //   key: "heading",
    //   text: "Heading",
    //   className: "role",
    //   align: "left",
    //   sortable: true,
    // },
    // {
    //   key: "text",
    //   text: "Text",
    //   className: "image",
    //   align: "left",
    //   sortable: true,
    // },
    // {
    //   key: "image",
    //   text: "Image",
    //   className: "image",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     const imagess = record?.image;
    //     return (
    //       <>
    //         <img className="img-fluid" src={imagess} alt="" />
    //       </>
    //     );
    //   },
    // },
    {
      key: "url",
      text: "Url",
      className: "image",
      align: "left",
      sortable: true,
    },
    {
      key: "type",
      text: "Type",
      className: "image",
      align: "left",
      sortable: true,
    },

    {
      key: "createdAt",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record?.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },

    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            {/* <button onClick={() => deleteAlert(record?.id)} title="Delete team">
              <span className="mdi mdi-trash-can-outline"></span>
            </button> */}
            <button onClick={() => edit(record)} title="Update team">
              <span className="mdi mdi-square-edit-outline"></span>
            </button>
          </>
        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const deleteAlert = (Deleteid) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => addressDelete(Deleteid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const addressDelete = async (id) => {
    let data = {
      id,
    };

    // deleteaddress(data).then((result) => {
    //   if (result.data.status) {
    //     toast.dismiss();
    //     toast.success(result.data.message);
    //   }
    // });
  };

  const handleShow = (id) => {
    setShow(true);
  };

  const edit = (record) => {
    setUpdateShow(true);
    setCurrentRecord(record);
  };

  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="section-heading">
                <h2>
                  <b>Document</b>
                </h2>
                <div className="add-product-btn ">
                  <button
                    className="btn btn-primary"
                   onClick={handleShow}
                  >
                    Add/Update Document
                  </button>
                </div>
              </div>
              <AddDocuments show={show} setShow={setShow} />
              <UpdateDocument
                updateShow={updateShow}
                setUpdateShow={setUpdateShow}
                currentRecord={currentRecord}
              />
              <div className="product-list-outer card p-3">
                <ReactDatatable
                  config={config}
                  records={record}
                  columns={columns}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
