import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { InputValid } from "../../validations/InputValid";
import { toast } from "react-toastify";
import { useUpdateSideBarMutation } from "../../../redux/sidebarApi";

function UpdateSideBarModal(props) {
  const [updateSideBar] = useUpdateSideBarMutation();
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [icon, setIcon] = useState("");
  const [iconErr, setIconErr] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    if (props.currentRecord) {
      setName(props.currentRecord.name);
      setId(props.currentRecord.id);
      setIcon(props.currentRecord.icon)
    }
  }, [props]);

  const handleClose = () => {
    setName("");
    setNameErr("");
    setIcon("");
    setIconErr("");
    props.setUpdateShow(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "name") {
      setName(value);
      const err = InputValid(name, value);
      setNameErr(err);
    }
    if (name === "icon") {
      setIcon(value);
      const err = InputValid(name, value);
      setIconErr(err);
    }
  };

  const onsubmit = async (e) => {
    const checkName = InputValid("name", name);
    if (checkName) {
      setNameErr(checkName);
      return false;
    }

    const data = {
      name,
      id,
      icon,
      router_name: name.toLocaleLowerCase(),
    };
    updateSideBar(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };
  return (
    <div>
      <Modal show={props.updateShow} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Update SideBar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Name </Form.Label>
              <Form.Control
                name="name"
                onChange={handlechange}
                type="text"
                value={name}
              ></Form.Control>
              <span style={{ color: "red" }}>{nameErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Icon </Form.Label>
              <Form.Control
                name="icon"
                onChange={handlechange}
                type="text"
                value={icon}
              ></Form.Control>
              <span style={{ color: "red" }}>{iconErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UpdateSideBarModal;
