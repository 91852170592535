import { addAnnouncementApi, deleteAnnouncementApi, getAnnouncementApi, updateAnnouncementApi, updateAnnouncementStatusApi } from "../Components/constant/Api";
  import { myApi } from "./api";
  
  export const announcementApi = myApi.injectEndpoints({
    endpoints: (builder) => ({
      getAnnouncement: builder.query({
        query: () => ({
          url: getAnnouncementApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? "" : "";
        },
        providesTags: (_) => ["announcement"],
      }),
      setAnnouncement: builder.mutation({
        query: (post) => ({
          url: addAnnouncementApi,
          method: "POST",
          body: post,
        }),
        invalidatesTags: (_) => ["announcement","dashboard"],
      }),
      deleteAnnouncement: builder.mutation({
        query: (post) => ({
          url: deleteAnnouncementApi + "/" + post.id,
          method: "DELETE",
        }),
        invalidatesTags: (_) => ["announcement","dashboard"],
      }),
      updateStatusAnnouncement: builder.mutation({
          query: (post) => ({
            url: updateAnnouncementStatusApi + "/" + post.id,
            method: "PUT",
          }),
          invalidatesTags: (_) => ["announcement"],
        }),
        updateAnnouncement: builder.mutation({
          query: (post) => ({
            url: updateAnnouncementApi,
            method: "PUT",
            body:post
          }),
          invalidatesTags: (_) => ["announcement"],
        }),
    }),
  });
  
  export const {
    useGetAnnouncementQuery,
    useSetAnnouncementMutation,
    useDeleteAnnouncementMutation,
    useUpdateStatusAnnouncementMutation,
    useUpdateAnnouncementMutation
  } = announcementApi;
  