import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { InputValid } from "../../validations/InputValid";
import { toast } from "react-toastify";
import { useSetSideBarMutation } from "../../../redux/sidebarApi";

function AddSideBarModal(props) {
  const [setSideBar] = useSetSideBarMutation();
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [icon, setIcon] = useState("");
  const [iconErr, setIconErr] = useState("");

  const handleClose = () => {
    setName("");
    setNameErr("");
    setIcon("")
    setIconErr("")
    props.setShow(false);
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "name") {
      setName(value);
      const err = InputValid(name, value);
      setNameErr(err);
    }
    if (name === "icon") {
      setIcon(value);
      const err = InputValid(name, value);
      setIconErr(err);
    }
  };

  const onsubmit = async (e) => {
    const checkName = InputValid("name", name);
    if (checkName) {
      setNameErr(checkName);
      return false;
    }

    const checkIcon = InputValid("icon", icon);
    if (checkIcon) {
      setIconErr(checkIcon);
      return false;
    }

    const data = {
      name,
      icon,
      router_name:name.toLocaleLowerCase()
    };
    setSideBar(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };
  return (
    <div>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title style={{ color: "black" }}>Add SideBar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Name </Form.Label>
              <Form.Control
                name="name"
                onChange={handlechange}
                type="text"
                value={name}
              ></Form.Control>
              <span style={{ color: "red" }}>{nameErr}</span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Icon </Form.Label>
              <Form.Control
                name="icon"
                onChange={handlechange}
                type="text"
                value={icon}
              ></Form.Control>
              <span style={{ color: "red" }}>{iconErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onsubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddSideBarModal;
