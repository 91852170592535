import React, { useState } from "react";
import Header from "../Widgets/Header";
import Navbar from "../Widgets/Navbar";
import ReactDatatable from "@mkikets/react-datatable";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import {
  useDeletePartnershipMutation,
  useGetPartnershipQuery,
  useUpdateStatusPartnershipMutation,
} from "../../redux/partnershipApi";
import AddPartnerShipModal from "../partial/partnership/AddPartnerShipModel";
import UpdatePartnerShipModal from "../partial/partnership/UpdatePartnerShipModal";

function Partnership() {
  const { data: record } = useGetPartnershipQuery();
  const [updateStatusPartnership] = useUpdateStatusPartnershipMutation();
  const [deletePartnership] = useDeletePartnershipMutation();
  const [show, setShow] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const columns = [
    {
      key: "srno.",
      text: "Sr.No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "brand_name",
      text: "Brand Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "link",
      text: "Link",
      className: "link",
      align: "left",
      sortable: true,
    },
    {
      key: "image",
      text: "Image",
      className: "image",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <img style={{ height: "50px" }} src={record.image} alt="" />
          </>
        );
      },
    },

    {
      key: "createdAt",
      text: "Date",
      className: "date",
      align: "left",
      sortable: true,
      cell: (record) => {
        const date = record.createdAt;
        return <>{moment(date).format("DD/MM/YYYY")}</>;
      },
    },
    {
      key: "status",
      text: "Status",
      className: "Action ",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              style={{
                border: "1px solid #fff",
                borderRadius: "3px",
                background: record.status === "1" ? "green" : "#d10202",
              }}
              onClick={() => {
                updateAlert(record.id);
              }}
              title={record.status === "1" ? "Inactive" : "Active"}
            >
              <i className="fa fa-lock" style={{ color: "#fff" }}></i>
            </button>
          </>
        );
      },
    },
    {
      key: "action",
      text: "Action",
      className: "Action",
      align: "left",
      sortable: true,
      cell: (record) => {
        return (
          <>
            <button
              onClick={() => deleteAlert(record.id)}
              title="Delete partnership"
            >
              <span className="mdi mdi-trash-can-outline"></span>
            </button>
            <button onClick={() => edit(record)} title="Update partnership">
              <span className="mdi mdi-square-edit-outline"></span>
            </button>
          </>

        );
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No record found!",
    button: {
      // print: true,
      // csv: true,
    },
    language: {
      filter: "Search in records...",

      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const updateAlert = (id) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => UpdateStatus(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const UpdateStatus = async (id) => {
    let data = {
      id,
    };
    updateStatusPartnership(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      }
    });
  };

  const deleteAlert = (Deleteid) => {
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => teamDelete(Deleteid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const teamDelete = async (id) => {
    let data = {
      id,
    };
    deletePartnership(data).then((result) => {
      if (result.data.status) {
        toast.dismiss();
        toast.success(result.data.message);
      }
    });
  };

  const handleShow = (id) => {
    setShow(true);
  };

  const edit = (record) => {
    setUpdateShow(true);
    setCurrentRecord(record);
  };

  return (
    <div id="layout-wrapper">
      <Header />
      <Navbar />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="section-heading">
              <h2>
                <b>Partnership List</b>
              </h2>
              <div className="add-product-btn ">
                <button
                  className="btn btn-primary"
                  onClick={() => handleShow()}
                >
                  Add Partnership
                </button>
              </div>
            </div>

            <div className="product-list-outer card p-3">
             
              <ReactDatatable
                config={config}
                records={record}
                columns={columns}
              />
            </div>
            <AddPartnerShipModal show={show} setShow={setShow} />
            <UpdatePartnerShipModal
              updateShow={updateShow}
              setUpdateShow={setUpdateShow}
              currentRecord={currentRecord}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partnership;
