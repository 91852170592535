import { addSideBarApi, deleteSideBarApi, getSideBarApi, updateSideBarApi, updateSideBarStatusApi } from "../Components/constant/Api";
  import { myApi } from "./api";
  
  export const sidebarApi = myApi.injectEndpoints({
    endpoints: (builder) => ({
      getSideBar: builder.query({
        query: () => ({
          url: getSideBarApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? "" : "";
        },
        providesTags: (_) => ["sidebar"],
      }),
      setSideBar: builder.mutation({
        query: (post) => ({
          url: addSideBarApi,
          method: "POST",
          body: post,
        }),
        invalidatesTags: (_) => ["sidebar","dashboard"],
      }),
      deleteSideBar: builder.mutation({
        query: (post) => ({
          url: deleteSideBarApi + "/" + post.id,
          method: "DELETE",
        }),
        invalidatesTags: (_) => ["sidebar","dashboard"],
      }),
      updateStatusSideBar: builder.mutation({
          query: (post) => ({
            url: updateSideBarStatusApi + "/" + post.id,
            method: "PUT",
          }),
          invalidatesTags: (_) => ["sidebar"],
        }),
        updateSideBar: builder.mutation({
          query: (post) => ({
            url: updateSideBarApi,
            method: "PUT",
            body:post
          }),
          invalidatesTags: (_) => ["sidebar"],
        }),
    }),
  });
  
  export const {
    useGetSideBarQuery,
    useSetSideBarMutation,
    useDeleteSideBarMutation,
    useUpdateStatusSideBarMutation,
    useUpdateSideBarMutation
  } = sidebarApi;
  