import {
    addDesoApi,
    deleteDesoApi,
    getDesoApi,
  
    
  } from "../Components/constant/Api";
  import { myApi } from "./api";
  
  export const desoApi = myApi.injectEndpoints({
    endpoints: (builder) => ({
      getDeso: builder.query({
        query: () => ({
          url: getDesoApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? "" : "";
        },
        providesTags: (_) => ["deso"],
      }),
      setDeso: builder.mutation({
        query: (post) => ({
          url: addDesoApi,
          method: "POST",
          body: post,
        }),
        invalidatesTags: (_) => ["deso", "dashboard"],
      }),
      deleteDeso: builder.mutation({
        query: (post) => ({
          url: deleteDesoApi + "/" + post.id,
          method: "DELETE",
        }),
        invalidatesTags: (_) => ["deso", "dashboard"],
      }),
   
    }),
  });
  
  export const {
    useGetDesoQuery,
    useSetDesoMutation,
    useDeleteDesoMutation,
 
  } = desoApi;