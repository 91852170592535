import {
    addAiApi,
    deleteAiApi,
    getAiApi,
  
    
  } from "../Components/constant/Api";
  import { myApi } from "./api";
  
  export const aiApi = myApi.injectEndpoints({
    endpoints: (builder) => ({
      getAi: builder.query({
        query: () => ({
          url: getAiApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? "" : "";
        },
        providesTags: (_) => ["ai"],
      }),
      setAi: builder.mutation({
        query: (post) => ({
          url: addAiApi,
          method: "POST",
          body: post,
        }),
        invalidatesTags: (_) => ["ai", "dashboard"],
      }),
      deleteAi: builder.mutation({
        query: (post) => ({
          url: deleteAiApi + "/" + post.id,
          method: "DELETE",
        }),
        invalidatesTags: (_) => ["ai", "dashboard"],
      }),
   
    }),
  });
  
  export const {
    useGetAiQuery,
    useSetAiMutation,
    useDeleteAiMutation,
 
  } = aiApi;
  