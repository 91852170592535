import {
  addTokenSaleTimeApi,
  getTokenSaleTimeApi,
  updateTokenSaleTimeStatusApi,
} from "../Components/constant/Api";
import { myApi } from "./api";

export const tokensaletimeApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    getTokenSaleTime: builder.query({
      query: () => ({
        url: getTokenSaleTimeApi,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? "" : "";
      },
      providesTags: (_) => ["tokensaletime"],
    }),
    setTokenSaleTime: builder.mutation({
      query: (post) => ({
        url: addTokenSaleTimeApi,
        method: "POST",
        body: post,
      }),
      invalidatesTags: (_) => ["tokensaletime"],
    }),
    updateStatusTokenSaleTime: builder.mutation({
      query: (post) => ({
        url: updateTokenSaleTimeStatusApi + "/" + post.id,
        method: "PUT",
      }),
      invalidatesTags: (_) => ["tokensaletime"],
    }),
  }),
});

export const { useGetTokenSaleTimeQuery, useSetTokenSaleTimeMutation,useUpdateStatusTokenSaleTimeMutation } =
  tokensaletimeApi;
