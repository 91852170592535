const sideMenuList = [
  {
    id: 1,
    title: "Dashboard",
    route: "/dashboard",
    icon: "mdi mdi-home",
    is_show: "is_dashboard",
  },
  {
    id: 6,
    title: "Team",
    route: "/team",
    icon: "mdi mdi-account-group-outline",
    is_show: "is_promotion_banner",
  },
  {
    id: 6,
    title: "FAQ",
    route: "/faq",
    icon: "mdi mdi-frequently-asked-questions",
    is_show: "is_promotion_banner",
  },
  {
    id: 6,
    title: "Announcement",
    route: "/announcement",
    icon: "mdi mdi-bullhorn-variant-outline",
    is_show: "is_promotion_banner",
  },
  {
    id: 6,
    title: "WhitePaper",
    route: "/whitepaper",
    icon: "mdi mdi-newspaper-variant-outline",
    is_show: "is_promotion_banner",
  },
  {
    id: 6,
    title: "PartnerShip",
    route: "/partnership",
    icon: "mdi mdi-handshake-outline",
    is_show: "is_promotion_banner",
  },
  {
    id: 6,
    title: "Video",
    route: "/video",
    icon: "mdi mdi-video-outline",
    is_show: "is_promotion_banner",
  },
  {
    id: 6,
    title: "SideBar",
    route: "/sidebar",
    icon: "mdi mdi-menu-open",
    is_show: "is_promotion_banner",
  },
  {
    id: 6,
    title: "Token sale Time",
    route: "/token-sale-time",
    icon: "mdi mdi-menu-open",
    is_show: "is_promotion_banner",
  },
  {
    id: 7,
    title: "Smart Contract Address",
    route: "/address",
    icon: "mdi mdi-menu-open",
    is_show: "is_promotion_banner",
  },
  {
    id: 8,
    title: "Wallet Address",
    route: "/wallet-address",
    icon: "mdi mdi-menu-open",
    is_show: "is_promotion_banner",
  },
  {
    id: 9,
    title: "About Us",
    route: "/about-us",
    icon: "mdi mdi-menu-open",
    is_show: "is_promotion_banner",
  },
  {
    id: 10,
    title: "Ai",
    route: "/ai",
    icon: "mdi mdi mdi-cogs",
    is_show: "is_promotion_banner",
  },
  {
    id: 11,
    title: "Deso",
    route: "/deso",
    icon: "mdi mdi-menu-open",
    is_show: "is_promotion_banner",
  },
  {
    id: 12,
    title: "Gaming",
    route: "/gaming",
    icon: "mdi mdi mdi-gamepad",
    is_show: "is_promotion_banner",
  },
  {
    id: 13,
    title: "Vision",
    route: "/vision",
    icon: "mdi mdi mdi-eye",
    is_show: "is_promotion_banner",
  },
  {
    id: 14,
    title: "Mission",
    route: "/mission",
    icon: "mdi mdi mdi-rocket",
    is_show: "is_promotion_banner",
  },
  {
    id: 15,
    title: "Social Media",
    route: "/social-media",
    icon: "mdi mdi-menu-open",
    is_show: "is_promotion_banner",
  },
  {
    id: 16,
    title: "Road Map",
    route: "/roadmap",
    icon: "mdi mdi mdi-map",
    is_show: "is_promotion_banner",
  },
  {
    id: 17,
    title: "Document/TokenBuy",
    route: "/document",
    icon: "mdi mdi mdi-file-document",
    is_show: "is_promotion_banner",
  },

  
];
export default sideMenuList;
